import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/base-component';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';

export class AppBootstrap {
  constructor() {
    this.validateForms();
  }

  validateForms() {
    const forms = Array.from(document.querySelectorAll('.needs-validation'));
    forms.forEach((form) => {
      this.formValidator(form as HTMLFormElement);
    });
  }

  formValidator(form: HTMLFormElement) {
    form.addEventListener(
      'submit',
      (event: Event) => {
        if (!form.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();

          const invalidFeedback = form.querySelectorAll('.invalid-feedback');
          invalidFeedback.forEach((field) => {
            field.remove();
          });

          let errorDiv: HTMLDivElement;
          const invalidFields = form.querySelectorAll('.form-control:invalid');
          invalidFields.forEach((field) => {
            errorDiv = document.createElement('div');
            errorDiv.innerHTML =
              field.getAttribute('data-validity-message')?.toString() || 'This field is required.';
            errorDiv.classList.add('invalid-feedback', 'd-block');
            field.closest('.required')?.appendChild(errorDiv);
          });

          invalidFields[0]?.scrollIntoView({
            behavior: 'smooth'
          });
        }
        form.classList.add('was-validated');
      },
      false
    );
  }
}
